import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import InvalidURL from "./invalidURL";
import NoJobFound from "./noJobFound";
import ServerError from "./serverError";
import { Triangle, InfinitySpin } from 'react-loader-spinner';



const JobBody = () => {

    const shareOption = () => {
        console.log("hisdja")
        navigator.share({
            title: "Job vacancy for " + job + " at " + owner,
            text: `Hello 👋, New job vacancy for ` + job + ` at ` + owner + `. `+ `\n\n` +`To apply for the job click on the link below`+ `\n\n`,
            url: window.location.href
        })
            .then(() => console.log('Successful share'),
                error => console.log('Error sharing:', error));
    }
    const [loading, setLoading] = useState(true)
    const [validURL, setValidURL] = useState(true);
    const [minage, setMinAge] = useState('');
    const [maxage, setMaxAge] = useState('');
    const [minexp, setMinExp] = useState('');
    const [maxexp, setMaxExp] = useState('');
    const [salary, setSalary] = useState('');
    const [vacancies, setVacancies] = useState('');
    const [applied, setApplied] = useState('');
    const [job, setJob] = useState('');
    const [owner, setOwner] = useState('');
    const [img, setImg] = useState('');
    const [code, setCode] = useState(0);

    const [ids, setId] = useSearchParams();
    ids.get("id");
    useEffect(() => {
        // const id = "636b50ff9606f15838a72ac5";
        const id = ids.get('id');
        async function getValues() {
            await axios.post('https://api.jobro.in/user/v1/job/get/particular/public', { id })
                .then(response => {
                    if (response.data.response_code === 200) {
                        setMinAge(response.data.response.data.preferred_age.min);
                        setMaxAge(response.data.response.data.preferred_age.max);
                        setMinExp(response.data.response.data.experience.min);
                        setMaxExp(response.data.response.data.experience.max);
                        setSalary(response.data.response.data.salary);
                        setVacancies(response.data.response.data.no_of_vacancies);
                        setApplied(response.data.response.data.applied_users);
                        setJob(response.data.response.data.job_type.label);
                        setOwner(response.data.response.data.owner.shop_name);
                        setImg(response.data.response.data.image);
                    }
                    setCode(response.data.response_code);
                    setLoading(false);
                }).catch(e => {
                    setLoading(false)
                });
        }
        function IdTester() {
            let url = window.location.href;
            if (url.includes('id')) {
                setValidURL(true);
                console.log("paramenter");
            } else {
                console.log('No Parameters in URL');
            }
        }
        getValues();
        IdTester();


    }, []);


    return (
        <div className='mainContent'>
            {/* <Lines customLoading={loading} /> */}
            {loading === true ? (
                <div className='preload'>
                    <InfinitySpin
                        width='200'
                        color="#076AFF"
                        wrapperStyle={{ justifyContent: "center", marginTop: "30vh", border: "10px solid black" }}

                    />
                </div>

            ) : (validURL && code === 200 ? (
                <main>
                    <div className="job-post-company ">
                        <div className="container">
                            <div className="row justify-content-between z">
                                <div className="col-xl-5 col-lg-8 x">
                                    <div className="single-job-items">
                                        <div className="job-items">
                                            <div className="company-img company-img-details text-center mr-75">
                                                <img src={img} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-4 col-sm-3 y">
                                    <div className="post-details3 ml-30">
                                        <div className="small-section-tittle">
                                            <h1 className="job-heading">{job}</h1>
                                            <h2 className="job-subheading">{owner}</h2>
                                        </div>
                                        <ul>
                                            <li className="heading">Preferred Age <span>Job Info</span></li>
                                            <li className="subheading">{minage}-{maxage} years<span>Not Provided</span></li>
                                            <li className="heading">Experience <span>Salary</span></li>
                                            <li className="subheading">{minexp}-{maxexp} year <span>Rs. {salary} monthly</span></li>
                                            {/* <li className="heading">Salary :  <span>Application date</span></li> */}
                                            <li className="last-row">No. of Vacancies : {vacancies}<span>No. of Applied : {applied}</span></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div className="apply-btn2">
                                <button type="button" className="btn btn-primary btn-lg btn1 w-50" onClick={() => { window.location.href = "https://bit.ly/jobro_seeker" }}>Apply Here</button>
                                <button type="button" className="btn btn-primary btn-lg btn2 w-50" onClick={shareOption}>Share</button>
                            </div>
                        </div>
                    </div>
                </main>
            ) : validURL && code === 404 ? (
                <NoJobFound />
            ) : validURL && code === 400 ? (
                <InvalidURL />
            ) : validURL && code === 500 ? (
                <ServerError />
            ) : (
                <InvalidURL />
            )
            )}
        </div>
    )
}
export default JobBody