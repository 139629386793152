import React from 'react';
import error from '../assets/img/Under construction-bro.svg';
import JobDesc from './jobDesc';
const Maintainance = () => {
  return (
    <div>
        <JobDesc />
        <div className='error-image'>
            <img src={error} alt='Error 400 Bad Request' />
        </div>
        <div className="error-apply-btn2">
          <button type="button" className="btn btn-primary btn-lg btn1 w-50" onClick={() => {window.location.href = "https://bit.ly/jobro_merchant"}}>Post a job</button>
          <button type="button" className="btn btn-primary btn-lg btn2 w-50" onClick={() => {window.location.href = "https://bit.ly/jobro_seeker"}}>Find a job</button>
        </div>
    </div>
  )
}
export default Maintainance