import React from 'react'

const jobDesc = () => {
  return (
    <header>
      <div className="header-area header-transparrent">
            <a href="/" className="comp-name">JOBRO</a>
      </div>
    </header>
  )
}

export default jobDesc;