import { Helmet } from "react-helmet";
import "./assets/css/bootstrap.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/slicknav.css";
import "./assets/css/price_rangs.css";
import "./assets/css/animate.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/themify-icons.css";
import "./assets/css/slick.css";
import "./assets/css/nice-select.css";
import "./assets/css/style.css";
import "./assets/css/job.css"
import JobDesc from "./components/jobDesc";
import JobBody from "./components/jobBody";


function App() {
  return (
    <div className="App">
        <Helmet>
            <meta charSet="utf-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <title>Jobro | Job Search & Post App</title>
            <meta name="description" content="" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="manifest" href="site.webmanifest" />
        <link rel="shortcut icon" type="image/x-icon" href="assets/img/favicon.ico" />

      </Helmet>

        {/* <div id="preloader-active">
            <div className="preloader d-flex align-items-center justify-content-center">
                <div className="preloader-inner position-relative">
                    <div className="preloader-circle"></div>
                    <div className="preloader-img pere-text">
                        <img src="assets/img/logo/logo.png" alt="" />
                    </div>
                </div>
            </div>
        </div> */}
        
        <JobDesc />
        <JobBody />
        
    </div>
  );
}

export default App;
