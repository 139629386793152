import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,Route, Routes  } from 'react-router-dom';
import App from './App';
import InvalidURL from "./components/invalidURL";
import Maintainance from './components/Maintainance';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <BrowserRouter>
        <Routes>
            <Route path='/job-listing' element={<App />} />
            <Route path='/' element={<Maintainance />} />
                
        </Routes>
    </BrowserRouter>
    </>
    

);

